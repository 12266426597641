import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2172124d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { style: {"font-size":"14px"} }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "p-col-12 p-md-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_UpdateInfos = _resolveComponent("UpdateInfos")!
  const _component_UpdatePassword = _resolveComponent("UpdatePassword")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Divider, {
      align: "center",
      class: "p-col-12"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Tag, {
          severity: "info",
          class: "p-d-flex p-flex-column"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("workspaceLayout.lastConnexion")), 1),
            (_ctx.Iam?.lastLoginAt)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$d(_ctx.Iam.lastLoginAt, "long")), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    (!_ctx.IamLoading)
      ? (_openBlock(), _createBlock(_component_UpdateInfos, {
          key: 0,
          user: _ctx.Iam,
          class: "p-col-12 p-md-6"
        }, null, 8, ["user"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_UpdatePassword)
    ])
  ]))
}