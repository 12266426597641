
import { defineComponent } from "vue";
import UploadImage from "@/components/common/UploadImage.vue";
import { useUpdateUser } from "@/graphql/user/update.user";
import { useValidation } from "vue3-form-validation";
import { getImage } from "@/graphql/utils/utils";

export default defineComponent({
  name: "UpdateInfos",
  props: ["user"],
  components: { UploadImage },
  setup(props) {
    const { form, validateFields, hasError } = useValidation({
      language: "0",
      name: {
        $value: "",
        $rules: [(n: string) => !n && ""],
      },
    });
    form.name.$value = String(props.user.name);
    form.language = String(props.user.language);

    const { loading, updateUser, active, image } = useUpdateUser();

    function submitUpdateUser() {
      validateFields().then((input) => {
        const { language, ...res } = input;
        updateUser({
          ...res,
          language: Number(language),
        });
      });
    }
    return {
      form,
      validateFields,
      hasError,
      loading,
      active,
      image,
      submitUpdateUser,
      getImage,
    };
  },
});
