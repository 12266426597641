import { useMutation } from "@vue/apollo-composable";
import { MutationUpdateUserArgs, UpdateUserInput, User } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { USER_FIELDS } from "@/graphql/user/user.sdl";
import { ref } from "vue";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { trim } from "@/graphql/utils/utils";

type UpdateUserData = {
  updateUser: User;
};
const UPDATE_USER = gql`
    mutation UpdateUser($input: UpdateUserInput!, $image: Upload) {
        updateUser(input: $input, image: $image) {
            ${USER_FIELDS}
        }
    }
`;
export const useUpdateUser = (notify = true) => {
  const toast = useToast();
  const { t } = useI18n();
  const image = ref<File>(null);
  const active = ref<boolean[]>([false, false]);
  const { loading, mutate, onDone } = useMutation<
    UpdateUserData,
    MutationUpdateUserArgs
  >(UPDATE_USER, { context: { hasUpload: true } });
  onDone(({ data }) => {
    if (notify) {
      toast.add({
        severity: "success",
        summary: t("update.title"),
        detail: t(`update.${data.updateUser ? "success" : "failed"}`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
    active.value = [false, false];
  });

  function updateUser(input: UpdateUserInput) {
    trim(input);
    void mutate({
      input,
      image: image.value,
    });
  }
  return {
    loading,
    updateUser,
    image,
    active,
  };
};
