
import { defineComponent } from "vue";
import { useUpdatePassword } from "@/graphql/user/update.password";

export default defineComponent({
  name: "UpdatePassword",
  setup() {
    return {
      ...useUpdatePassword(),
    };
  },
});
