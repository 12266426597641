import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-273decaf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Image = _resolveComponent("Image")!
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_openBlock(), _createBlock(_component_FileUpload, {
    "show-cancel-button": false,
    "show-upload-button": false,
    onRemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', null))),
    multiple: false,
    accept: "image/*",
    "choose-label": _ctx.$t('choosePhoto'),
    "file-limit": 1,
    onSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSelect($event.files))),
    invalidFileTypeMessage: _ctx.$tm('invalidFileTypeMessage'),
    ref: "uploader"
  }, {
    empty: _withCtx(() => [
      (_ctx.src)
        ? (_openBlock(), _createBlock(_component_Image, {
            key: 0,
            preview: "",
            height: _ctx.height || 150,
            src: _ctx.src
          }, null, 8, ["height", "src"]))
        : (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t("previewImage")), 1)),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["choose-label", "invalidFileTypeMessage"]))
}