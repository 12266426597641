
import { defineComponent } from "vue";
import UpdatePassword from "@/components/account/UpdatePassword.vue";
import UpdateInfos from "@/components/account/UpdateInfos.vue";
import { useWHoAmI } from "@/graphql/user/whoAmi";
export default defineComponent({
  name: "Account",
  components: { UpdatePassword, UpdateInfos },
  setup() {
    return {
      ...useWHoAmI(),
    };
  },
});
