import { useMutation } from "@vue/apollo-composable";
import { MutationUpdatePasswordArgs, User } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useToast } from "primevue/usetoast";
import { useValidation } from "vue3-form-validation";
import { useI18n } from "vue-i18n";

type UpdatePasswordData = {
  updatePassword: User;
};
const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      id
    }
  }
`;

export const useUpdatePassword = () => {
  const { t } = useI18n();
  const { validateFields, hasError, form } = useValidation({
    currentPassword: {
      $value: "",
      $rules: [(n: string) => !n && t("validation.required")],
    },
    newPassword: {
      $value: "",
      $rules: [
        (n: string) => !n && t("validation.required"),
        {
          key: "pw",
          rule: (p: string, c: string) =>
            p !== c && t("signUp.validation.password"),
        },
      ],
    },
    confirm: {
      $value: "",
      $rules: [
        (n: string) => !n && t("validation.required"),
        {
          key: "pw",
          rule: (p: string, c: string) =>
            p !== c && t("signUp.validation.password"),
        },
      ],
    },
  });
  const toast = useToast();
  const { loading, mutate, onDone } = useMutation<
    UpdatePasswordData,
    MutationUpdatePasswordArgs
  >(UPDATE_PASSWORD);
  onDone(({ data }) => {
    if (data?.updatePassword) {
      toast.add({
        severity: "success",
        summary: t("update.title"),
        detail: t("update.success"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else {
      toast.add({
        severity: "warn",
        summary: t("update.title"),
        detail: t("signIn.wrongCurrentPassword"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });
  function updatePassword() {
    validateFields().then((value) => {
      const { currentPassword, newPassword } = value;
      void mutate({ input: { currentPassword, newPassword } });
    });
  }
  return { loading, validateFields, hasError, form, updatePassword };
};
