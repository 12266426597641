import { useQuery, useResult } from "@vue/apollo-composable";
import { User } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { USER_FIELDS } from "@/graphql/user/user.sdl";

export type WhoAmIData = {
  whoAmI: User;
};
const defaultUser = {
  id: 0,
  activation: 0,
  createdAt: undefined,
  email: "",
  image: "",
  language: 0,
  lastLoginAt: undefined,
  name: "",
  settings: [2, 1, 0, 1, 1, 0], // [layoutMode, color scheme, input style, ripple effect, menu theme, component themes]
};
export const WHOAMI = gql`
  query WhoAmI {
    whoAmI {
      ${USER_FIELDS}
    }
  }
`;

export const useWHoAmI = () => {
  const { loading: IamLoading, result } = useQuery<WhoAmIData>(WHOAMI);
  const Iam = useResult<WhoAmIData | null, typeof defaultUser, User>(
    result,
    defaultUser,
    (res) => res.whoAmI
  );
  return { IamLoading, Iam };
};
