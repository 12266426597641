
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "UploadImage",
  props: ["modelValue", "height", "src"],
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const uploader = ref<any>(null);
    watch(
      () => props.modelValue,
      (v) => {
        if (!v) uploader.value.clear();
      }
    );
    return {
      onSelect: (files: any[]) => {
        if (files.length > 1) {
          files.splice(0, 1);
        }
        emit("update:modelValue", files[0]);
      },
      uploader,
    };
  },
});
